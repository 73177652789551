var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commune-upload"},[(_vm.listeDossiers)?_c('div',[(
        _vm.parentData.numEtape > '6' &&
        _vm.parentData.codeStatut !== '81' &&
        _vm.showNbrTotal() > 0
      )?_c('div',{staticClass:"record-title h4"},[_c('strong',{staticStyle:{"color":"#ff4b00"}},[_vm._v(_vm._s(_vm.i18nSuivi.logoText))]),_c('br')]):_vm._e(),_c('br'),(_vm.showNbrTotal() > 0)?_c('span',[_c('strong',[_c('br'),_vm._v(_vm._s(_vm.i18nSuivi.uploadTitle))]),_c('br'),_vm._v(" "+_vm._s(_vm.i18nSuivi.uploadSubtitle)+" ")]):_vm._e(),_c('br'),_c('br'),_vm._l((_vm.listeDossiers),function(listeCpt,numeroCpt){return _c('div',{key:numeroCpt},[(_vm.tableBoolean !== undefined)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"flex-row header-row"},[_c('div',{staticClass:"meter-id col-xxs-20 col-s-5"},[_c('span',{class:_vm.parentData.typeFluide === 'Gaz' ? 'icon-gaz' : 'icon-elec'}),_vm._v(" "+_vm._s(_vm.i18nSuivi.compteurNumber)+" "+_vm._s(numeroCpt + 1)+" ")]),_c('div',{staticClass:"ean-id col-xxs-20 col-s-8"},[_vm._v(" "+_vm._s(listeCpt.ean ? _vm.i18nSuivi.ean + ' ' + listeCpt.ean : '')+" "+_vm._s(_vm.i18nSuivi.numOfficiel)+" "+_vm._s(listeCpt.numRue)+" "+_vm._s(listeCpt.numComp !== '' ? listeCpt.numComp : '')+" ")]),(_vm.showNbrTotalCpt(numeroCpt) > 0)?_c('div',{staticClass:"col-xxs-20 col-s-7 end"},[_c('button',{staticClass:"action",class:_vm.isClosed(numeroCpt),on:{"click":function($event){return _vm.develop(numeroCpt)}}},[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.processPluriel(
                    _vm.i18nSuivi.uploadAction,
                    _vm.showNumberAction(
                      numeroCpt,
                      _vm.parentData.numDossier[0],
                      listeCpt.numDossier[0]
                    ) - _vm.nonNecessaireNbr(numeroCpt)
                  )
                )}}),_c('span',{staticClass:"icon-close-open"})])]):_c('div',{staticClass:"col-xxs-20 col-s-7 end"},[_c('button',{staticClass:"action--big--btn checked"},[_c('span',[_vm._v(_vm._s(_vm.i18nSuivi.uploadComplete))])])])]),(_vm.showDevelop(numeroCpt))?_c('div',{staticClass:"toggle on"},[_c('div',{staticClass:"warning-banner"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.processAction(
                  _vm.i18nSuivi.uploadHeadSubTxt,
                  _vm.showNumberAction(
                    numeroCpt,
                    _vm.parentData.numDossier[0],
                    listeCpt.numDossier[0]
                  ) - _vm.nonNecessaireNbr(numeroCpt)
                )
              )}})]),_vm._l((listeCpt.liste),function(compteur,index){return _c('div',{key:index,staticClass:"documents-list"},[(
                compteur.valeurInfo === 'NECESSAIRE' ||
                compteur.valeurInfo === 'NECESSAIRE_SUR_PLACE' ||
                compteur.valeurInfo === 'RECU'
              )?_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"doc-name col-xxs-20 col-xs-20 col-s-9"},[_vm._v(" "+_vm._s(compteur.libInfo)),_c('br'),_c('i',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(compteur.valeurInfo === 'NECESSAIRE_SUR_PLACE' && compteur.typeInfo === 'DOC' && compteur.codeInfo !== '84_1' && compteur.codeInfo !== '84_3' ? _vm.i18nSuivi.uploadDocPostalTxt : ''))]),_c('i',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s((compteur.codeInfo === '61_1' || compteur.codeInfo === '84_3') && compteur.valeurInfo === 'NECESSAIRE_SUR_PLACE' && compteur.valeurInfo !== 'RECU' ? _vm.i18nSuivi.pvReception : ''))]),_c('i',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(compteur.codeInfo === '84_1' && compteur.valeurInfo !== 'RECU' ? _vm.i18nSuivi.certifConformite : ''))]),_c('i',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s((compteur.codeInfo === '84_2' || compteur.codeInfo === '61_2') && compteur.valeurInfo !== 'RECU' ? _vm.i18nSuivi.fournitureContrat : ''))]),_c('span',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(compteur.typeInfo === 'POSTAL' ? _vm.i18nSuivi.uploadPostalTxt : ''))])]),(
                  !_vm.fileAlreadyUpload(
                    compteur.codeInfo,
                    numeroCpt,
                    _vm.parentData.numDossier[0],
                    listeCpt.numDossier[0]
                  ) && compteur.typeInfo === 'DOC'
                )?_c('div',{staticClass:"doc-status col-xxs-20 col-xs-10 col-s-6"},[_vm._v(" "+_vm._s(compteur.typeInfo === 'DOC' && compteur.valeurInfo !== 'RECU' && compteur.valeurInfo !== 'NECESSAIRE_SUR_PLACE' ? _vm.i18nSuivi.waitingReception : '')+" ")]):_vm._e(),(
                  compteur.typeInfo === 'DOC' &&
                  compteur.valeurInfo !== 'RECU' &&
                  compteur.valeurInfo !== 'NECESSAIRE_SUR_PLACE'
                )?_c('div',{staticClass:"doc-button col-xxs-20 col-xs-10 col-s-5 end"},[(
                    !_vm.fileAlreadyUpload(
                      compteur.codeInfo,
                      numeroCpt,
                      _vm.parentData.numDossier[0],
                      listeCpt.numDossier[0]
                    )
                  )?_c('span',{staticClass:"action__label"},[_c('g-action',{staticClass:"mb-s action--big--btn",attrs:{"content":{
                      label: _vm.i18nSuivi.uploadDocumentUploaded,
                      tag: 'button',
                      modifiers: ['big'],
                    }},nativeOn:{"click":function($event){return _vm.showUploadPanel(
                        $event,
                        compteur,
                        _vm.parentData.numDossier[0],
                        listeCpt.numDossier[0],
                        numeroCpt,
                        false
                      )}}})],1):(
                    compteur.libInfoStatut !== 'En attente en réception'
                  )?_c('span',{staticClass:"action__label"},[_c('button',{staticClass:"mb-s action--big--btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showUploadPanel(
                        $event,
                        compteur,
                        _vm.parentData.numDossier[0],
                        listeCpt.numDossier[0],
                        numeroCpt,
                        true
                      )}}},[_c('span',{staticClass:"action__label"},[_vm._v(_vm._s(_vm.i18nSuivi.uploadDocumentReploaded))])])]):_vm._e()]):(compteur.valeurInfo === 'RECU')?_c('div',{staticClass:"col-m-5"},[_c('span',{staticClass:"action__label"},[_c('button',{staticClass:"mb-s action--big--btn checked",attrs:{"type":"button"}},[_c('span',{staticClass:"action__label"},[_vm._v(_vm._s(_vm.i18nSuivi.uploadRecu))])])])]):_c('div',{staticClass:"col-m-5"},[_c('span',{staticClass:"action__label"},[_c('button',{staticClass:"mb-s action--big--btn not-send",attrs:{"type":"button"}},[_c('span',{staticClass:"action__label"},[_vm._v(_vm._s(_vm.i18nSuivi.uploadNonRecu))])])])])]):_vm._e()])}),_c('div',[(_vm.fileToSend(numeroCpt, listeCpt.numDossier[0]) > 0)?_c('span',{staticClass:"action__label"},[_c('br'),_c('hr'),_c('div',{staticClass:"flex-row send-file"},[_c('p',{staticClass:"col-m-10"},[_vm._v(_vm._s(_vm.i18nSuivi.dateWarn))]),_c('g-action',{staticClass:"col-xxs-20 col-m-5 mb-s action--big--btn right",attrs:{"content":{
                    label: 'Envoyer',
                    tag: 'button',
                    modifiers: ['big'],
                  }},nativeOn:{"click":function($event){return _vm.sendWs109(
                      _vm.parentData.numDossier[0],
                      listeCpt.numDossier[0],
                      numeroCpt,
                      false
                    )}}})],1)]):_vm._e()])],2):_vm._e()]):_vm._e()])})],2):(_vm.parentData.codeStatut !== '81')?_c('div',[_c('g-loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }