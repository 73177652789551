























































































































































































































































































/* eslint-disable */
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import Tip from '@/components/Tip.vue'
import UploadPanel from '@/components/UploadPanel.vue'
import { ShowCompteur } from '@/inc/types'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import moment from 'moment'

export default defineComponent({
  name: 'commune-upload',
  components: { Tip },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { $route } = ctx.root
    const { id } = $route.params
    const { files, i18n, i18nSuivi, consentement } = useState('my', [
      'files',
      'i18n',
      'i18nSuivi',
      'consentement',
    ])
    const { SET_CONSENTEMENT, UPDATE_FILE } = useMutations('my', [
      'SET_CONSENTEMENT',
      'UPDATE_FILE',
    ])
    const listeDossiers = ref<any | null>([])
    const allFilesSended = ref(false)
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const showLoader = ref(false)
    let tableBoolean = Array<ShowCompteur>()
    const returnValue = ref(false)

    if (_props.parentData.dossierInf.length > 0) {
      listeDossiers.value.push(_props.parentData.dossierInf)
      for (let i in _props.parentData.dossierInf) {
        tableBoolean.push({
          index: parseInt(i),
          value: false,
          isSend: false,
          idDossier: _props.parentData.dossierInf[i].numDossier[0],
          idParent: _props.parentData.numDossier[0],
          finish: false,
        })
      }
    } else {
      axios
        .get(`${getApiUrl()}/town/works/records/${id}`)
        .then(response => {
          if (response.status === 200) {
            if (response.data.liste.dossierInf > 0) {
              listeDossiers.value.push(response.data.dossierInf)
            } else {
              listeDossiers.value.push(response.data)
            }
          }
          showLoader.value = true
        })
        .catch(e => {
          logger.error('[FETCH DATA] ERROR WS 59')
        })
      tableBoolean.push({
        index: 0,
        value: false,
        isSend: false,
        idDossier: _props.parentData.numDossier[0],
        idParent: _props.parentData.numDossier[0],
        finish: false,
      })
    }

    const develop = index => {
      tableBoolean[index].value = !tableBoolean[index].value
    }
    const isClosed = index => {
      if (tableBoolean[index].value) {
        return 'closed'
      }
      return 'opened'
    }
    const showDevelop = index => {
      return tableBoolean[index].value
    }
    const actionNecessaire = index => {
      let downloadedFile = 0
      let necessaireList = 0

      for (let i in files.value) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          files.value[i].finish
        ) {
          tableBoolean[index].finish = true
          //allFilesSended.value = true
        } else {
          tableBoolean[index].finish = false
        }
      }

      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          files.value[i].index === index
        ) {
          downloadedFile++
        }
      }

      for (let i in listeDossiers.value[index].liste) {
        if (
          listeDossiers.value[index].liste[i].typeInfo === 'DOC' &&
          (listeDossiers.value[index].liste[i].valeurInfo === 'NECESSAIRE' ||
            listeDossiers.value[index].liste[i].valeurInfo ===
              'NECESSAIRE_SUR_PLACE') &&
          listeDossiers.value[index].liste[i].codeInfo !== '61_3'
        ) {
          necessaireList++
        }
      }
      if (necessaireList === 0) {
        tableBoolean[index].finish = true
      }
      return necessaireList - downloadedFile
    }
    // Methode pour calculer le nombre d'actions restant
    const showNumberAction = (index, id, idCpt) => {
      let necessaire = 0
      let downloadedFile = 0
      // pour chaque dossier, calcul le nombre de fichier à upload en fonction de la valeur info

      for (let i in listeDossiers.value[index].liste) {
        if (
          listeDossiers.value[index].liste[i].valeurInfo === 'NECESSAIRE' ||
          listeDossiers.value[index].liste[i].valeurInfo ===
            'NECESSAIRE_SUR_PLACE' ||
          //listeDossiers.value[index].liste[i].valeurInfo === 'NON_NECESSAIRE' ||
          listeDossiers.value[index].liste[i].valeurInfo === 'DOC' ||
          (listeDossiers.value[index].liste[i].valeurInfo === 'CHECK' &&
            listeDossiers.value[index].liste[i].codeInfo !== '61_3')
        ) {
          necessaire++
        }
      }
      // Va rechercher dans le store le nombre de fichier qui ont déjà été upload
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          !files.value[i].header
        ) {
          if (index === files.value[i].index) {
            downloadedFile++
          }
        }
      }
      for (let i in consentement.value) {
        if (
          consentement.value[i].idCpt === idCpt &&
          consentement.value[i].idDossier === id &&
          consentement.value[i].index === index
        ) {
          return necessaire - downloadedFile
        }
      }
      return necessaire
    }
    const showNbrTotal = () => {
      let necessaire = 0
      let allFiles = 0

      for (let i in listeDossiers.value) {
        for (let j in listeDossiers.value[i].liste) {
          if (
            listeDossiers.value[i].liste[j].valeurInfo === 'NECESSAIRE' ||
            //listeDossiers.value[index].liste[i].valeurInfo === 'NON_NECESSAIRE' ||
            listeDossiers.value[i].liste[j].valeurInfo === 'CHECK' ||
            listeDossiers.value[i].liste[j].valeurInfo ===
              'NECESSAIRE_SUR_PLACE'
          ) {
            necessaire++
          }
        }
      }
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          !files.value[i].header
        ) {
          allFiles++
        }
      }
      return necessaire - allFiles
    }
    const showNbrTotalCpt = index => {
      let necessaire = 0

      for (let j in listeDossiers.value[index].liste) {
        if (
          listeDossiers.value[index].liste[j].valeurInfo === 'NECESSAIRE' ||
          //listeDossiers.value[index].liste[i].valeurInfo === 'NON_NECESSAIRE' ||
          listeDossiers.value[index].liste[j].valeurInfo === 'CHECK' ||
          listeDossiers.value[index].liste[j].valeurInfo ===
            'NECESSAIRE_SUR_PLACE'
        ) {
          necessaire++
        }
      }
      return necessaire
    }
    const fileToSend = (index, idCpt) => {
      let fileHeader = 0
      for (let i in files.value) {
        if (
          files.value[i].idDossier === _props.parentData.numDossier[0] &&
          files.value[i].idCompteur === idCpt &&
          files.value[i].index === index &&
          files.value[i].uploadAndSend === false
        ) {
          fileHeader++
        }
      }
      return fileHeader
    }
    const nonNecessaireNbr = index => {
      let necessaire = 0

      for (let j in listeDossiers.value[index].liste) {
        if (
          ((listeDossiers.value[index].liste[j].valeurInfo === 'NECESSAIRE' ||
            listeDossiers.value[index].liste[j].valeurInfo ===
              'NECESSAIRE_SUR_PLACE') &&
            listeDossiers.value[index].liste[j].typeInfo === 'INFO') ||
          (listeDossiers.value[index].liste[j].valeurInfo ===
            'NECESSAIRE_SUR_PLACE' &&
            listeDossiers.value[index].liste[j].typeInfo === 'DOC')
        ) {
          necessaire++
        }
      }
      return necessaire
    }
    // Methode permettant d'afficher le side panel pour envoyer un fichier en lui indiquant des infos
    const showUploadPanel = (
      e,
      compteur,
      idDossier,
      idCompteur,
      index,
      renvoyer
    ) => {
      e.preventDefault()

      showPanel({
        component: {
          template: UploadPanel,
        },
        content: {
          compteur,
          idDossier,
          idCompteur,
          ean: _props.parentData.ean,
          numCompteur: index,
          header: false,
          renvoyer,
        },
      })
    }
    // Methode pour check dans le store si je viens d'envoyer un fichier afin de changer le statut en envoyé
    const fileAlreadyUpload = (fileCode, index, id, idCpt) => {
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idFile === fileCode &&
          files.value[i].index === index &&
          files.value[i].idDossier === id &&
          files.value[i].idCompteur === idCpt &&
          files.value[i].uploadAndSend === true
        ) {
          return true
        }
      }
    }
    const processAction = (text: string, action: string) => {
      let html = text.replace('%nbrAction', action)
      if (Number(action) > 1) {
        html = html.replace('%verbe', i18nSuivi.value.verbDocuments)
      } else {
        html = html.replace('%verbe', i18nSuivi.value.verbDocument)
        html = html.replace('(s)', '')
        html = html.replace('les', 'le')
      }
      return html
    }
    const processPluriel = (text: string, action: string) => {
      let html = text.replace('%nbrAction', '' + action)
      if (Number(action) <= 1) {
        html = html.replace('(s)', '')
      }
      return html
    }
    const loaderRenvoyer = ref(false)
    const sendWs109 = (id, idCpt, index, renvoyer) => {
      for (let i = 0; i < files.value.length; i++) {
        if (
          files.value[i].idDossier === id &&
          files.value[i].idCompteur === idCpt &&
          files.value[i].index === index
        ) {
          UPDATE_FILE({
            idDossier: id,
            idCompteur: idCpt,
            idFile: files.value[i].idFile,
            index: index,
            uploadAndSend: true,
          })
        }
      }
      if (!renvoyer) {
        SET_CONSENTEMENT({
          idDossier: id,
          idCpt: idCpt,
          index: index,
          isSend: false,
          header: false,
        })
        for (let i in tableBoolean) {
          if (
            tableBoolean[i].idDossier === idCpt &&
            tableBoolean[i].idParent === id &&
            tableBoolean[i].index === index
          ) {
            tableBoolean[i].finish = true
          }
        }
        axios
          .get(
            `${getApiUrl()}/works/confirmation?NumDossier=${id}&DateAccord=${moment().format(
              'DD/MM/YYYY'
            )}&TypeAction=UPLOAD_DOC`
          )
          .then()
          .catch(e => {
            logger.error('[SEND CONFIRMATION] Error WS109')
          })
      } else {
        loaderRenvoyer.value = true
        axios
          .get(
            `${getApiUrl()}/works/confirmation?NumDossier=${id}&DateAccord=${moment().format(
              'DD/MM/YYYY'
            )}&TypeAction=UPLOAD_DOC`
          )
          .then(resp => {
            if (resp.status === 200) {
              loaderRenvoyer.value = false
            }
          })
          .catch()
      }
    }
    return {
      i18n,
      i18nSuivi,
      listeDossiers,
      tableBoolean,
      showLoader,
      allFilesSended,
      files,
      returnValue,
      loaderRenvoyer,
      fileToSend,
      sendWs109,
      actionNecessaire,
      showNumberAction,
      showNbrTotal,
      showNbrTotalCpt,
      nonNecessaireNbr,
      processAction,
      processPluriel,
      develop,
      showUploadPanel,
      fileAlreadyUpload,
      isClosed,
      showDevelop,
    }
  },
})
